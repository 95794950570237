import { TRichText } from 'src/utils/parseRichText'

export const SECURITY_DESCRIPTION_LIST_ITEMS = [
  {
    title: 'ISO 27001 certification',
    description: [
      {
        type: 'p',
        value:
          'We are a certified <a href="https://www.iso.org/isoiec-27001-information-security.html" target="_blank">ISO 27001</a> company, demonstrating to regulatory authorities and other interested parties that we take security of information we hold seriously and, having identified risks, we do as much as is reasonably possible to address them.',
      },
      { type: 'br' },
      { type: 'br' },
      {
        type: 'p',
        value:
          'Our ISMS framework helps to reduce the likelihood of breaches, and the controls we have in place reduce the potential impacts of these security risks which is fundamental to our partners.  This internationally recognised ‘best-practice’ standard allows our clients to feel safe and secure, and reassures partners that we look after their valuable assets and information security.',
      },
    ] as TRichText,
  },

  {
    title: 'Secure connections',
    description:
      'All traffic between our APIs and client-facing applications is encrypted and served over HTTPS (enforced with HSTS) using the strongest TLS security policies. We also submit all of our applications to external penetration testing.',
  },

  {
    title: 'Access control lists',
    description:
      'We deploy group-based and role-based access control policies for data access on both the infrastructure and application level. We also keep a full audit log of administrative and user access.',
  },

  {
    title: 'Encryption of sensitive data',
    description:
      'All sensitive data in our applications is encrypted at rest using AES-256 Ciphers. We only keep sensitive data on privately accessible servers, never public.',
  },

  {
    title: 'Alerting & monitoring',
    description:
      "Our applications are monitored and alerts are automatically triggered if something looks out of the ordinary. This tells us if there's a problem, so you don't have to.",
  },

  {
    title: 'Infrastructure isolation & protection',
    description:
      'Each of our applications is deployed within the AWS Cloud and is isolated on its own resources - within its own account and inside its own VPC. Sensitive resources, such as databases, are only able to communicate through private connections and are not available for access on the outside internet.',
  },
  {
    title: 'Cyber Essential+ certification',
    description: [
      {
        type: 'p',
        value:
          'Cyber Essential+ is a prestigious certification that demonstrates our commitment to robust cybersecurity practices and protection against online threats. It is a government-backed scheme designed to ensure that organisations like ours have implemented essential security controls to safeguard sensitive data and maintain the integrity of our digital infrastructure.',
      },
      { type: 'br' },
      { type: 'br' },
      {
        type: 'p',
        value:
          'By achieving Cyber Essential+ certification, we have undergone a rigorous assessment of our cybersecurity measures, including network security, access controls, patch management, and malware protection. This certification signifies that we have taken proactive steps to mitigate risks and fortify our defences against cyberattacks.',
      },
      { type: 'br' },
      { type: 'br' },
      {
        type: 'p',
        value:
          'With Cyber Essential+ certification, our clients and partners can trust that we prioritise the security of their data and information. It serves as a testament to our dedication to maintaining a secure online environment, safeguarding against potential cyber threats, and providing a foundation for secure business operations.',
      },
    ] as TRichText,
  },
]
