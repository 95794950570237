import { ArContainer } from 'src/components/ArContainer'
import { ArDescriptionList } from 'src/components/ArDescriptionList'
import { ArSeo } from 'src/components/ArSeo'
import { ArTextHero } from 'src/components/ArTextHero'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { SECURITY_DESCRIPTION_LIST_ITEMS } from 'src/data/securityDescriptionList'

const SecurityPage = () => {
  return (
    <>
      <ArSeo route={ROUTES.security()} />
      <ArContainer>
        <ArTextHero
          title="Security"
          paragraph="Nothing is more important to us than your security. Sensitive data deserves world-class monitoring, strict access controls and high-level encryption."
        />
        <ArDescriptionList data={SECURITY_DESCRIPTION_LIST_ITEMS} />
      </ArContainer>
    </>
  )
}

export default SecurityPage
